.textareaHeight {
  height: 350px;
}

.locationPolicy {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 28px;
  align-items: baseline;
}

.contentBoxWidth480 {
  width: 480px;
}
