.homeListItem {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  background: white;
  border-radius: 5px;
}

.listItemCircle {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #cedef5;
}

.listItemText {
  width: 85%;
}

.listItemTextImg {
  width: 80%;
}

.listItemImage {
  max-height: 45px;
  object-fit: cover;
  width: 40px;
  border-radius: 3px;
}
