.getStarted {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  margin: 100px 0;
}

.gsTitle {
  font-family: "Montserrat Alternates";
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  text-align: center;
}

.gsDesc {
  font-family: "Montserrat";
  width: 390px;
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0.2;
  text-align: center;
  color: #69acc9;
}

.gsSection {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: flex-start;
}

.gsStep {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.gsStepTitle {
  display: flex;
  gap: 30px;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #464f7a;
}

.gsStepNumber {
  font-family: "Montserrat";
  font-size: 28px;
  font-weight: 700;
  padding: 10px 0;
  height: 20px;
  width: 10px;
  color: #464f7a;
  position: relative;
  z-index: 2;
  bottom: 20px;
  background-color: #f4f4fc;
}

.gsStepsContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-left: 10px;
  margin-bottom: 20px;
}

.gsStepNumber::after {
  content: " ";
  z-index: -1;
  width: 65px;
  transform: rotate(90deg);
  position: absolute;
  border-top: 1px dashed black;
  top: 80px;
  right: -29px;
}

.gsWrapper {
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 390px;
  border-radius: 10px;
  padding: 50px 45px 30px 45px;
  background-color: #f4f4fc;
  box-shadow: 0px 3px 6px rgba(75, 81, 91, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.15);
}

.dashedCenterText {
  text-align: center;
  position: relative;
}

.dashedCenterText::before {
  content: " ";
  width: 35px;
  z-index: 1;
  bottom: 9px;
  left: 0px;
  position: absolute;
  border-top: 1px dashed black;
}

.dashedCenterText::after {
  content: " ";
  width: 35px;
  z-index: 1;
  right: 0px;
  bottom: 9px;
  position: absolute;
  border-top: 1px dashed black;
}

.gsButton {
  margin-top: 30px;
  width: 255px;
  align-self: center;
}
