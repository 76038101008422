.contentBoxWrapper {
  width: 385px;
  display: flex;
  flex-direction: column;
  background: #f4f4fc;
  border-radius: 10px;
  gap: 15px;
  box-sizing: border-box;
  padding: 25px 30px 40px 30px;
  box-shadow: 0px 3px 6px rgba(75, 81, 91, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.15);
}

.contentBoxWrapper .defaultText {
  line-height: 20px;
}

.contentBoxSecondary .defaultText {
  font-weight: 600;
  font-size: 14px;
  color: #464f7acc;
}

.contentBoxSecondary .cardHeaderTitle {
  align-self: center;
}

.contentBoxSecondary {
  gap: 20px;
}

.paddingV30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
