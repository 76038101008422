.rWrapper {
  padding: 23px 25px;
  flex-direction: row;
  gap: 30px;
  align-items: flex-start;
}

.remindersImg {
  width: 100px;
  height: auto;
}

.remindersText {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
