.dropDownList {
  border-radius: 3px;
  border: none;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Montserrat Alternates";
  align-self: center;
  box-shadow: none;
  color: #444444;
}
