.defaultInput {
  background: #ffffff;
  border: none;
  text-align: left;
  border-radius: 8px;
  padding: 15px 20px;
  box-sizing: border-box;
  width: 100%;
}

.defaultInputSearch {
  background: url(../../public/images/search.svg) no-repeat scroll 8px 10px;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  border-radius: 0;
}

.defaultInputFont::placeholder {
  color: #dbdbdb;
}

.defaultInputFont {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.defaultInput:focus {
  outline: none;
}
