.dashboard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 55px 0;
  padding-bottom: 100px;
  gap: 40px;
  align-items: center;
}

.locationWrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  width: 380px;
  padding: 30px 45px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='black' stroke-width='2' stroke-dasharray='5%2c 5' stroke-dashoffset='20' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;
}

.rWrapper {
  padding: 23px 25px;
  flex-direction: row;
  gap: 30px;
  align-items: flex-start;
}

.remindersTitle {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #444444;
}

.remindersImg {
  width: 100px;
  height: auto;
}

.remindersText {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.remindersTutorials {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.listItemContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.gap20Column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.gap15 {
  gap: 15px;
}

.mb12 {
  margin-bottom: 12px;
}

.dashboardRight {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.width360 {
  width: 360px;
}

.dashboardLocationsContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.mt20 {
  margin-top: 20px;
}

.mb20 {
  margin-bottom: 20px;
}
