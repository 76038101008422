.locationInfo {
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.locationInfoTitle {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-align: left;
  color: #25a9e0;
  background: linear-gradient(155.49deg, #69c8c9 50.91%, #25a9e0 80.66%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.textSize14 {
  font-size: 14px;
}

.dietOptions {
  display: flex;
  flex-direction: column;
  gap: 35px;
}
