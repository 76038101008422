.restaurantMenu {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  overflow: hidden;
}

/* Left stick side dragable menu items */
.dragableComponents {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: left auto;
  width: 300px;
  background-color: white;
}

.scrollableNestedList {
  padding: 30px 25px 30px 25px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}

.scrollableNestedList::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  border-radius: 4px;
  background-color: white;
}

.scrollableNestedList::-webkit-scrollbar-thumb {
  background-color: #464f7a;
  border-radius: 4px;
  height: 10px;
}

.menuPadding {
  padding: 20px 25px 15px 25px;
}

.searchInputContainer {
  background: #f4f4fc;
  padding: 20px 40px 25px 25px;
  border-top: 1px solid #beaeae;
}
.restaurantMenuRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: min-content;
  flex: 0.95;
  max-width: 1300px;
  padding-top: 5rem;
  padding-left: 60px;
  padding-bottom: 20px;
  gap: 30px;
}
.width480 {
  width: 480px;
}

.width200 {
  width: 200px;
}

.gap10 {
  gap: 10px;
}

.gap25 {
  gap: 25px;
}

.burgerBox {
  border: none;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: stretch;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 7px;
  padding: 13px;
  background-color: #464f7a;
}

.line {
  height: 2px;
  border-radius: 1px;
  background-color: white;
}

.wholeMenu {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.scrollableMenuItems {
  height: 100%;
  overflow: scroll;
  padding-bottom: 100px;
  padding-top: 30px;
}

.scrollableMenuItems::-webkit-scrollbar {
  display: none;
}

.gap50 {
  gap: 50px;
}

.lineHR {
  background-color: black;
  height: 1px;
}

.click {
  cursor: pointer;
}
