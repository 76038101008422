.menuCard {
  padding: 0;
}

.menuCardTop {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 3px 6px rgba(75, 81, 91, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.gap10 {
  gap: 10px;
}

.gap25 {
  gap: 25px;
}

.dottedDiv {
  width: 12px;
  height: 12px;
  border-radius: 100%;
}

.redDot {
  background-color: #ad320b;
}

.greenDot {
  background-color: #566f43;
}

.menuItemCardVerification {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menuCardBelow {
  padding: 0 20px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.menuAllergenTokenContainer {
  display: flex;
  row-gap: 15px;
  column-gap: 7px;
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: 10px;
}

.menuDietTokenContainer {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: 10px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.textTokenContainer {
  display: flex;
  flex-direction: column;
  width: 380px;
}

.width140 {
  width: 140px;
}
