.visualCommandCard {
  padding: 20px 30px;
  cursor: pointer;
}
.blueWrapper {
  background: #25a9e0;
}

.visualCommandCardImg {
  height: 110px;
  width: auto;
  max-width: 250px;
}
