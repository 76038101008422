.defaultButton {
  border: none;
  border-radius: 3rem;
  min-width: 40px;
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  font-weight: 700;
  font-family: "Montserrat Alternates";
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  box-sizing: border-box;
  transition-duration: 0.2s;
  cursor: pointer;
  /* hc gradient */
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
}

.cyan {
  background: linear-gradient(160.4deg, #69c8c9 1.04%, #25a9e0 187.95%);
}

/* Normal text with underline only */
.skeleton {
  border: none;
  border-radius: none;
  color: #464f7a;
  width: auto;
  text-align: center;
  text-decoration: underline;
  display: inline;
  background-color: transparent;
  font-size: 14px;
  padding: 0px 10px;
  margin: 0;
  font-weight: 400;
  cursor: pointer;
  box-shadow: none;
  align-self: center;
}

.cancelButton {
  text-decoration: none;
  font-weight: 700;
  color: #ad320b;
}

.skeleton:hover {
  background-color: transparent;
}

.width180 {
  width: 180px;
}

.width240 {
  width: 240px;
}

.purple {
  background: #464f7a;
  box-shadow: none;
}

.fontSize12 {
  font-size: 12px;
}

.alignCenter {
  align-self: center;
}

.disabled {
  background-color: #e6e6e6;
  background: #e6e6e6;
  color: #b3b3b3;
  cursor: not-allowed;
}

.borderButton {
  border: 1px solid #444444;
  background: transparent;
  box-shadow: none;
  color: #444444;
}

.borderButtonRed {
  border: 1px solid #ad320b;
  background: transparent;
  box-shadow: none;
  color: #ad320b;
}

.paddingV12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.paddingV15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.paddingV25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.rad5 {
  border-radius: 5px;
}

.blue {
  background: #25a9e0;
  box-shadow: none;
}

.width280 {
  width: 280px;
}
