.dietaryInfoCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;
  width: fit-content;
  padding: 15px 25px 10px 25px;
  background-color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.dietImage {
  height: 35px;
  width: auto;
}

.modalImage {
  height: 65px;
  width: auto;
}

.modalButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.modalImageText {
  display: flex;
  flex-direction: column;
  gap: 18px;
  justify-content: center;
}

.dietText {
  font-family: "Montserrat Alternates";
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #444444;
  width: max-content;
}

.activeDietCard {
  background-color: #464f7a;
}

.activeDietCard .dietText {
  color: #ffffff;
}
