.defaultTextArea {
  resize: none;
  border: none;
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.defaultTextArea::placeholder {
  color: #cbcbcb;
}

.defaultTextArea:focus,
.defaultTextArea:active {
  border: none;
  outline: none;
}
