.allergenDiv {
  background: #e8e7e5;
  border-radius: 4px;
  padding: 5px 15px;
}

.containsAllergenToken {
  background: #25a9e0;
}

.modifiableAllergenToken {
  background: #d16a0a;
}

.activeAllergenToken .defaultText {
  color: white;
}

.allergenTokenBlue {
  background: #25a9e0;
}

.allergenTokenBlue .defaultText,
.allergenTokenOrange .defaultText {
  color: white;
}

.allergenTokenOrange {
  background: #d16a0a;
}
