.alertComponent {
  background: #ad320b;
  color: #fff;
  padding: 20px;
  width: 90%;
  border-radius: 16px;
  box-shadow: 0px 3px 6px rgba(75, 81, 91, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.15);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.alertText {
  font-family: "Montserrat";
  font-size: 18px;
  text-align: left;
  padding-left: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  width: 80%;
}

.error {
  background: #ad320b;
}

.success {
  background: #566f43;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.9;
  }

  100% {
    opacity: 0;
  }
}
