.slugPage {
  display: flex;
  align-content: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 100px;
  width: 90%;
  max-width: 1400px;
  margin: 100px;
}

.slugWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 3px 6px rgba(75, 81, 91, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px 50px 30px 30px;
  min-width: max-content;
  max-width: 90%;
  border-radius: 16px;
  background-color: #f4f4fc;
  gap: 10px;
}

.slugLink {
  height: min-content;
  align-items: center;
  display: flex;
  gap: 1rem;
  flex-direction: row;
  min-width: max-content;
  max-width: 90%;
  font-size: 16px;
  font-weight: 700;
}

.slugRightAlign {
  margin-left: auto;
}

.slugCenter {
  align-items: center;
  justify-items: center;
}
