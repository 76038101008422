.contactBoxWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 35px;
  box-sizing: border-box;
  padding: 20px 20px 20px 25px;
  width: 390px;
  background-color: #f4f4fc;
  box-shadow: 0px 3px 6px rgba(75, 81, 91, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.contactBoxContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.contactBoxImage {
  width: 40px;
  max-height: 50px;
  object-fit: contain;
}

.contactBoxTitle {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  color: #464f7a;
}

.contactBoxInput {
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 7px 12px;
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 12px;
  align-self: stretch;
}
