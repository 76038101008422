.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  min-height: 100%;
  min-width: 1024px;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalButton {
  background: #464f7a;
  box-shadow: none;
  border: 2px solid #464f7a;
  font-size: 14px;
  padding: 40px auto;
}

.closeButton {
  border: 1px solid #000000;
  background: transparent;
  color: #464f7a;
  box-shadow: none;
  font-size: 14px;
}

.modalContentBox {
  gap: 38px;
  padding: 35px 45px;
}

.minimumContainer {
  justify-content: space-between;
  width: 85%;
  max-width: 1400px;
}

.noGapsandPadding {
  padding: 0;
  margin: 0;
  gap: 0;
}
