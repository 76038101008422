.dietTokenText {
  color: #444444;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  margin: 0;
  font-family: "Montserrat Alternates";
}

.dietToken {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  box-sizing: border-box;
  cursor: pointer;
}

.dietTokenCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background: #ffffff;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
}

.dietTokenIcon {
  width: 100%;
}

.activeDietToken .dietTokenCircle {
  background: #ad320b;
}

.activeDietToken .dietTokenIcon {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
    hue-rotate(275deg) brightness(105%) contrast(101%);
}

.dietTokenSmall {
  gap: 8px;
  padding: 5px;
}

.dietTokenSmall .dietTokenCircle {
  width: 55px;
  height: 55px;
}

.dietTokenSmall .dietTokenText {
  font-weight: 500;
  font-size: 14px;
}
