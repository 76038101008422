.postRestaurant {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 20px;
  padding-top: 40px;
  padding-bottom: 100px;
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
}

.seperateLeftRight {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.postRestwrapper {
  background: #f4f4fc;
  box-shadow: 0px 3px 6px rgba(75, 81, 91, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px 50px 30px 30px;
  width: 100%;
}

.menuLink {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.restaurantLink {
  width: 300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.restaurantQR {
  display: flex;
  flex-direction: column;
  width: 200px;
  justify-content: center;
  align-items: center;
}

.copyLink {
  color: white;
  display: flex;
  gap: 1rem;
  flex-direction: row;
  background: black;
  padding: 10px 20px;
  font-family: "Calibri";
  font-size: 16px;
  font-weight: 700;
}

.postRestaurantLeft {
  width: 690px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
}

.postRestaurantRight {
  width: 490px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: center;
}

.postbtn {
  padding: 12px 25px;
}

.flex1 {
  flex: 1;
}

.menuPostTitle {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.2px;
  text-align: left;
  color: #464f7a;
}

.postBtnContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.preferenceSub {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.postSubTitle {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: rgba(68, 68, 68, 0.8);
}

.menuPreference {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 50px;
}

.toggleText {
  text-align: right;
  width: 40%;
}

.toggleElements {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.toggleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.changeDietModal {
  max-width: 1400px;
  width: 90%;
  gap: 25px;
  padding: 40px 50px;
}

.dotDiv {
  width: 15px;
  height: 15px;
  background-color: #ad320b;
  border-radius: 100%;
}

.dotText {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.mWidth450 {
  max-width: 450px;
  width: 50%;
}

.justifyBetween {
  justify-content: space-between;
}

.dietTokenContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
  column-gap: 40px;
  width: 60%;
  padding-bottom: 70px;
  padding-top: 10px;
}
