.defaultText {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: #464f7a;
}

.s10 {
  font-size: 10px;
}

.s12 {
  font-size: 12px;
}

.s14 {
  font-size: 14px;
}

.s16 {
  font-size: 16px;
}

.s18 {
  font-size: 18px;
}

.s20 {
  font-size: 20px;
}

.s24 {
  font-size: 24px;
}

.s28 {
  font-size: 28px;
}
.w4 {
  font-weight: 400;
}
.w5 {
  font-weight: 500;
}

.w6 {
  font-weight: 600;
}

.w7 {
  font-weight: 700;
}

.cyanText {
  color: #25a9e0;
}

.w8 {
  font-weight: 800;
}

.ttC {
  text-transform: capitalize;
}

.ttU {
  text-transform: uppercase;
}

.alignCenter {
  text-align: center;
}

.grayText {
  color: #444444;
}

.montAlt {
  font-family: "Montserrat Alternates";
}

.mont {
  font-family: "Montserrat";
}

.whiteText {
  color: white;
}

.blackText {
  color: black;
}

.greenText {
  color: #237526;
}

.redText {
  color: #ad320b;
}

.inline {
  display: inline;
}

.defaultText a {
  text-decoration: none;
  color: inherit;
}

.defaultText a:hover {
  color: inherit;
}

.row {
  flex-direction: row;
}
