.basicLocationInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 24px;
}

.uploadPhotoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 195px;
  background-color: white;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.uploadHere {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.uploadHere__plus {
  --b: 6px; /* the thickness */
  width: 40px; /* the size */
  aspect-ratio: 1;
  background: conic-gradient(
      from 90deg at var(--b) var(--b),
      #fff 90deg,
      #464f7a 0
    )
    calc(100% + var(--b) / 2) calc(100% + var(--b) / 2) / calc(50% + var(--b))
    calc(50% + var(--b));
  display: inline-block;
}

.uploadHere__text {
  font-size: 14px;
}

.reuploadBtn {
  width: 180px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Montserrat Alternates";
  background-color: transparent;
  border: 1px solid #444444;
  align-self: center;
  box-shadow: none;
  color: #444444;
}

.reuploadBtn:hover {
  background-color: transparent;
}

strong {
  font-weight: 700;
}

.centerBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
