.locationContact {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.locationContactTitle {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.2px;
  text-align: left;
  margin-bottom: 15px;
  color: #464f7a;
}
