@import "~reset-css/reset.css";

.RegistrationBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 80px;
}

.registrationCenter {
  display: flex;
  gap: 130px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.registrationTitle {
  font-family: "Montserrat Alternates";
  font-size: 36px;
  font-weight: 300;
  line-height: 50px;
  text-align: left;
  padding-right: 50px;
  align-self: center;
}

.registrationTextVid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 690px;
  gap: 70px;
}

.registrationVideo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 690px;
  gap: 70px;
  height: 390px;
}

.registrationVideo img {
  width: 100%;
}
