.restaurantLocations {
  width: 100%;
  padding: 25px 0;
}

.restaurantLocationsContainer {
  margin: 0 auto;
  max-width: 1400px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}

.rlocationsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
}

.mt20 {
  margin-top: 20px;
}

.restaurantLocations .locationBox {
  width: 280px;
}
