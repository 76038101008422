.dietTitle {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-align: left;
  color: #464f7a;
  width: max-content;
}

.dietItem {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.dietInfoCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 18px;
}
