@import "~reset-css/reset.css";

p {
  line-height: 1.25;
}

.gettingIn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 375px;
  max-width: min-content;
  border-radius: 16px;
  padding: 35px 60px 50px 60px;
  background-color: #f4f4fc;
  gap: 24px;
}

.logo {
  width: 80%;
  align-self: center;
}

.text {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #464f7a;
}
