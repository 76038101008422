.locationsSubBar {
  box-sizing: border-box;
  background-color: #f4f4fc;
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid rgba(105, 200, 201, 0.2);
}

.locationsSubBarContainer {
  max-width: 1400px;
  width: 90%;
  display: flex;
  padding: 15px 0;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
}

.buttonsContainer {
  display: flex;
  gap: 2em;
  flex-direction: row;
}

.subBarRestaurant {
  display: flex;
  gap: 8px;
}

.locationsSubBarText {
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 700;
  letter-spacing: 0.2px;
  text-align: left;
  color: #25a9e0;
}

.paddingH45 {
  padding-left: 45px;
  padding-right: 45px;
}
