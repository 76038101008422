.account {
  display: flex;
  gap: 25px;
  margin: 0 auto;
  width: 90%;
  max-width: 1400px;
  justify-content: center;
  align-items: flex-start;
  padding: 50px 0;
}
