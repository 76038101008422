.locationBox {
  height: 140px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-direction: column;
  cursor: pointer;
}

.locationBoxInactive {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23333' stroke-width='2' stroke-dasharray='30%2c 30' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 15px;
}

.locationBoxInactive:hover {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='white' stroke-width='2' stroke-dasharray='30%2c 30' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: #464f7a;
}

.locationBoxInactive:hover p {
  color: white;
}

.locationBoxInactive:hover img {
  filter: invert(100%) sepia(0%) saturate(7493%) hue-rotate(115deg)
    brightness(95%) contrast(106%);
}

.locationBoxImg {
  width: 100%;
  object-fit: cover;
}

.locationBoxOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    359.77deg,
    #000000 0.25%,
    rgba(0, 0, 0, 0) 96.51%
  );
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.locationBoxTextBelow {
  position: absolute;
  padding: 6%;
  bottom: 0;
  left: 0;
  width: 83%;
}

.locationBoxTextAbove {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  position: absolute;
  padding: 6%;
  padding-top: 4%;
  top: 0;
  left: 0;
  width: 83%;
}

.locationBoxCircle {
  width: 7px;
  height: 7px;
  background: #566f43;
  border-radius: 100%;
}
