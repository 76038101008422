.toggleButton {
  background: #fff;
  color: #464f7a;
  border: 1px solid #464f7a;
  margin: 0;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  user-select: none;
  font-family: "Montserrat";
  border: 1px solid #000000;
  padding: 4px 15px;
}

.toggleRight {
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.toggleLeft {
  border-radius: 5px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.selectedTb {
  background: #464f7a;
  color: #fff;
  border: 1px solid #464f7a;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  line-height: 1.5;
  text-align: center;
  user-select: none;
}
