.homeNavBar {
  width: 100%;
  background: white;
  box-sizing: border-box;
}

.homeNavBarContainer {
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 0;
}

.homeNavLinks {
  display: flex;
  flex-direction: row;
  gap: 80px;
  align-items: center;
  justify-content: center;
}

.homeSubText {
  font-family: "Heebo";
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-align: right;
  color: #a6acbe;
}

.homeText {
  font-family: "Heebo";
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.2px;
  text-align: right;
  color: #2e3b52;
}

.verticalLine {
  border-right: 1px solid #eff4f6;
  height: 65px;
  margin: 0 -20px;
}

.homeSingleLink {
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.cursorPointer {
  cursor: pointer;
}
