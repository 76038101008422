.locationMenuUpload {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 60px;
  padding: 60px 0;
}

.menuUpload {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: baseline;
  justify-content: center;
}

.fileUpload {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
}

.fileUploadPlaceholder {
  font-family: "Montserrat Alternates";
  font-size: 14px;
  padding: 15px;
  font-weight: 600;
  color: #cbcbcb;
}

.fileUploadButton {
  box-sizing: border-box;
  font-family: "Montserrat Alternates";
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  padding: 0 30px;
  color: white;
  background: #464f7a;
  border: none;
  cursor: pointer;
}

.menuItem {
  display: flex;
  justify-content: space-between;
}

.menuText {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.menuNumber {
  font-family: "Montserrat Alternates";
  font-size: 10px;
  font-weight: 600;
  text-align: left;
  color: #7b7b7b;
}

.menuUploadFileName {
  font-family: "Montserrat Alternates";
  font-size: 12px;
  font-weight: 400;
  color: #464f7a;
  text-transform: lowercase;
}

.uploadMoreText {
  font-family: "Montserrat Alternates";
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  color: #7b7b7b;
  padding: 15px 0;
  border-top: 1px solid #bdb6b6;
  border-bottom: 1px solid #bdb6b6;
}

.menuDelete {
  border: none;
  background: transparent;
  padding: 5px;
  cursor: pointer;
}
