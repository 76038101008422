/**
 * ==============================================
 * Dot Typing
 * ==============================================
 */

.staging {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  min-height: max-content;
  min-width: 40px;
  height: 20px;
  overflow: auto;
}

.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  color: white;
  box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  animation: dotTyping 1.5s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
  16.667% {
    box-shadow: 9984px -5px 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
  50% {
    box-shadow: 9984px 0 0 0 white, 9999px -5px 0 0 white, 10014px 0 0 0 white;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px -5px 0 0 white;
  }
  100% {
    box-shadow: 9984px 0 0 0 white, 9999px 0 0 0 white, 10014px 0 0 0 white;
  }
}
