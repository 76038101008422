.restaurantOverview {
  display: flex;
  flex-direction: column;
  width: 85%;
  min-width: 1200px;
  max-width: 1250px;
  margin: 0 auto;
  padding-top: 55px;
  padding-bottom: 100px;
  gap: 25px;
}

.restaurantDetailsContainer {
  display: flex;
  gap: 40px;
  justify-content: center;
}

.restaurantInfoMiddle {
  gap: 25px;
  padding: 25px 20px;
  height: 100%;
}

.alignEnd {
  align-self: flex-end;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.restaurantContentRight {
  gap: 30px;
}
.restaurantContentLeft {
  gap: 25px;
  justify-content: space-between;
}

.restaurantTopTitle {
  display: flex;
  justify-content: space-between;
}

.restaurantContactInfo {
  gap: 7px;
}

.height130 {
  height: 130px;
  width: auto;
  object-fit: cover;
}

.borderR5 {
  border-radius: 5px;
}

.scrollableDiets {
  display: flex;
  overflow-x: scroll;
  gap: 12px;
  flex-direction: row;
  padding-bottom: 25px;
  padding-top: 20px;
}

.blueWrapper {
  background: #25a9e0;
}

.scrollableDiets::-webkit-scrollbar {
  height: 6px;
  border-radius: 4px;
  background-color: #d8d9dd;
}

.scrollableDiets::-webkit-scrollbar-thumb {
  background-color: #464f7a;
  border-radius: 4px;
}
