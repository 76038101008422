.restaurantDetails {
  width: 100%;
  margin: 0 auto;
  max-width: 1500px;
  display: flex;
  flex-direction: row;
  gap: 100px;
  margin-top: 70px;
  padding-bottom: 80px;
  justify-content: flex-start;
  align-items: flex-start;
}

.sideNav {
  border-right: 1px dashed black;
  padding: 30px;
  margin-left: 80px;
}

.navLinks {
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: right;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.2px;
  width: max-content;
}

.navLinks a {
  color: rgba(68, 68, 68, 0.3);
  text-decoration: none;
  position: relative;
}

.navLinks a.active::after {
  content: " ";
  width: 13px;
  height: 32px;
  background-color: #464f7a;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -31px;
}

.disabledLink {
  cursor: none;
}
