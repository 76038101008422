.editButton {
  display: flex;
  flex-direction: row;
  gap: 12px;
  background-color: transparent;
  cursor: pointer;
  padding: 6px 20px;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  filter: drop-shadow(0px 3px 6px rgba(75, 81, 91, 0.15))
    drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.15));
  border: 1px solid #464f7a;
}

.alignCenter {
  align-self: center;
}

.gap5 {
  gap: 5px;
}

.purpleEdit .defaultText {
  color: white;
}

.purpleEdit {
  background-color: #464f7a;
}

.purpleEdit img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
    hue-rotate(275deg) brightness(105%) contrast(101%);
}
