.homeSubBar {
  display: flex;
  width: 100%;
  background: #f4f4fc;
  justify-content: center;
}

.homeSubBarContainer {
  display: flex;
  width: 90%;
  height: min-content;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.headerContainer {
  display: flex;
  min-height: 2.5rem;
  height: min-content;
  width: max-content;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
}

.alignItemCenter {
  align-items: center;
}
