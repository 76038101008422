.locationsNavBar {
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
}

.locationsNavBarContainer {
  max-width: 1400px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding: 35px 0;
  align-items: center;
}

.locationsHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 20px;
  align-items: center;
  color: #464f7a;
}

.clickableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10px;
}
.clickableContainer:hover {
  opacity: 0.9;
}

.navText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #2e3b52;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.marginRN {
  margin-right: -65px;
}

.circleElement {
  display: inline-flex;
  justify-items: center;
  align-items: center;
}

.lineElement {
  display: flex;
  margin-left: -1px;
  margin-right: -1px;
  background-color: #cedef5;
  width: 80px;
  height: 5px;
}

.circleActive {
  background-color: #464f7a;
}

.clear {
  background-color: transparent;
}

.circleDiv {
  display: block;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  box-sizing: border-box;
  border: 0.35em solid #464f7a;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
}

.selected {
  background-color: #464f7a;
}
